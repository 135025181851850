import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Linkek = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allLinksYaml.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Linkek"/>
      {posts.map(({ node }) => {
        return (
          <article>
            <header>
              <h3>
                <a href={node.link.url}>{node.link.title}</a>
              </h3>
              <small>
                URL {node.link.url}<br/>
                Kelt {node.link.date}, {node.link.author}
              </small>
            </header>
            <section>
              <p>{node.link.description}</p>
            </section>
          </article>
        )
      })}
    </Layout>
  )
}

export default Linkek

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allLinksYaml(sort: { fields: [link___date], order: DESC }) {
      edges {
        node {
          link {
            author
            date
            description
            language
            title
            url
          }
        }
      }
    }
  }
`
